.card-item-alternative {
  height: 590px;
  background: white;
  max-width: 407px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 6px 10px 4px rgba(0, 0, 0, 0.2);
  display: block;
  margin: 0 auto;

  .card-item-image {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 407px;

    img {
      height: 100%;
      width: 100%;
      min-width: 100%;
      max-width: none;
      object-fit: cover;
    }
  }

  .card-item-content {
    padding: 52px 25px 21px;
    position: relative;

    h5 {
      width: 100%;
      color: #191a25;
      font-family: 'Berlin Sans FB Regular';
      font-size: 24px;
      letter-spacing: 0;
      line-height: 30px;
      margin-bottom: 34px;
    }

    a {
      color: #3d7bfa;
      font-family: 'Berlin Sans FB Regular';
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: left;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 30px;

      svg {
        margin-left: 2px;
      }
    }
  }
}
